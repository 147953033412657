/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import X from "./x";
/// ICONS ///
import ShowChartIcon from '@mui/icons-material/ShowChart';
/// IMAGES //
import Signature from "static/signature.jpg";

const goToMeteora = () => {
  window.location.href = "https://dexscreener.com/solana/gzw224xdnvgw3uddavtcvisu5kcc95oglte5xcphepep";
}

export default function ArrisCoin() {
  return (
    <Box>
      <Grid container sx={{ paddingTop: "100px" }}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h1" sx={{ paddingBottom: "50px", color: "#0061ff" }}>
              <b>Arris Coin</b>
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip title="View on Meteora">
                <IconButton onClick={goToMeteora} size="large">
                  <ShowChartIcon sx={{ width: "36px", height: "36px" }} />
                </IconButton>
              </Tooltip>
              <X url="https://x.com/Arrisstones" />
            </Stack>
          </Stack>
          <Typography variant="h5">
            <b>Welcome to ArrisCoin/Sol</b>
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: "20px", paddingLeft: "5px" }}>
            ArrisCoin is a pioneering utility token intricately linked to Arris Stone Solutions, an innovative software-as-a-service (SaaS) provider revolutionizing the stone industry. Our B2B platform leverages cutting-edge technology and profound indstury expertise to enhance the operations of stone suppliers, dealer and fabricators through superior subscription-based solutions.
          </Typography>
          <Typography variant="h5" sx={{ paddingTop: "50px" }}>
            <b>Why Choose Arris Coin?</b>
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: "20px", paddingLeft: "5px" }}>
            ArrisCoin is a purpose-drive token designed to offer valuable incentives to its holders. By integrating ArrisCoin within our ecosystem, we provide unique opportunities for enhanced engagement and tangible benefits, ensuring our community is rewarded for their involvement and support. ArrisCoin is forward-thinking financial model that prioritizes your gains.
          </Typography>
          <Typography variant="h5" sx={{ paddingTop: "50px" }}>
            <b>Strategic Payment Mechanism</b>
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: "20px", paddingLeft: "5px" }}>
            Subscribers can pay monthly fees using ArrisCoin with 20% of these payments channeled in a locked liquidity pool, fortifying the coin's strength. This unique approach encourages subscribers to purchase ArrisCoin, effectively rewarding them with discounted subscriptions as the coin's value appreciates.
          </Typography>
          <Typography variant="h5" sx={{ paddingTop: "50px" }}>
            <b>Growth Potential</b>
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: "20px", paddingLeft: "5px" }}>
            By choosing ArrisCoin, investors have the opportunity to be part of a growing ecosystem. Not only does the value of ArrisCoin stand to increase, but it also incentivizes new clients to join Arris Stone Solutions, expanding our reach and influence.
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: "20px", paddingLeft: "5px" }}>
            Join us in reshaping the stone industry's future.
          </Typography>
          <img src={Signature} style={{ marginTop: "25px" }} alt="" />
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
    </Box>
  )
}
