export const home_url: string = "/";
export const about_url: string = "about";
export const merchant_url: string = "merchant";
export const fabricator_url: string = "fabricator";
export const plans_url: string = "plans";
export const email_confirmation_url: string = "confirm_email";
export const password_reset_url: string = "password_reset";
export const market_url: string = "market";
export const demo_url: string = "demo";
export const sign_order_url: string = "sign_order";
export const sign_direct_order_url: string = "sign_direct_order";
export const sign_quote_url: string = "sign_quote";
export const sign_direct_quote_url: string = "sign_direct_quote";
export const solana_url: string = "solana";
