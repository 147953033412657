import Tooltip from "@mui/material/Tooltip";
import { white_color } from "arris_dist";
import { text_primary } from "arris_dist";

interface Props {
  url: string
}

export default function XButton({ url }: Props) {
  const goToExternalUrl = () => {
    window.location.href = url;
  }

  return (
    <Tooltip title="X">
      <div
        onClick={goToExternalUrl}
        style={{ height: "28x", width: "28px", border: "none", backgroundColor: white_color, paddingTop: 3, paddingLeft: 1, cursor: "pointer" }}
      >
        <svg version="1.1" id="Logo_00000158727625646119494500000015711314265259652764_"
          xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1200 1227">
          <g id="Logo">
            <path fill={text_primary} d="M714.2,519.3L1160.9,0H1055L667.1,450.9L357.3,0H0l468.5,681.8L0,1226.4h105.9l409.6-476.2l327.2,476.2H1200
	L714.2,519.3L714.2,519.3z M569.2,687.8l-47.5-67.9L144,79.7h162.6l304.8,436l47.5,67.9l396.2,566.7H892.5L569.2,687.8L569.2,687.8z"/>
          </g>
        </svg>
      </div>
    </Tooltip>
  )
}
